/* eslint-disable linebreak-style */
import moment from 'moment-timezone';
import round from 'lodash/round';
import range from 'lodash/range';
import chroma from 'chroma-js';

const irradianceTypes = ['GNI', 'DNI', 'GFTI', 'GSTI', 'GBTI', 'GHI'];

export function parseEnergy(energy, decimalPlaces = 0) {
  if (energy / 1000000000 >= 1) return `${parseFloat((energy / 1000000000).toFixed(decimalPlaces))} GWh`;
  if (energy / 1000000 >= 1) return `${parseFloat((energy / 1000000).toFixed(decimalPlaces))} MWh`;
  if (energy / 1000 >= 1) return `${parseFloat((energy / 1000).toFixed(decimalPlaces))} kWh`;
  return `${parseFloat(energy.toFixed(decimalPlaces))} Wh`;
}

export function mapToUnit(type) {
  if (irradianceTypes.includes(type)) return 'W/m<sup>2</sup>';
  if (type === 'Pmp' || type === 'Pop' || type === 'Pmp STC') return 'W';
  if (type === 'Isc' || type === 'Imp' || type === 'Iop' || type === 'Imp STC') return 'A';
  if (type === 'Temperature') return '°C';
  return 'V';
}

export const axes = {
  V: {
    labels: {
      format: '{value}V'
    },
    title: {
      text: 'Voltage'
    },
    gridZIndex: -1,
    floor: 0,
    startOnTick: false,
    showEmpty: false
  },
  I: {
    labels: {
      format: '{value}A'
    },
    title: {
      text: 'Current'
    },
    gridZIndex: -1,
    floor: 0,
    startOnTick: false,
    showEmpty: false
  },
  P: {
    labels: {
      format: '{value}W'
    },
    title: {
      text: 'Power'
    },
    gridZIndex: -1,
    floor: 0,
    startOnTick: false,
    showEmpty: false
  },
  Energy: {
    labels: {
      format: '{value}Wh',
      formatter() {
        const energy = this.value;
        return parseEnergy(energy, 2);
      }
    },
    title: {
      text: 'Energy'
    },
    gridZIndex: -1,
    floor: 0,
    startOnTick: false,
    showEmpty: false
  },
  Irradiance: {
    labels: {
      format: '{value}W/m<sup>2</sup>',
      useHTML: true
    },
    title: {
      text: 'Irradiance'
    },
    gridZIndex: -1,
    floor: 0,
    startOnTick: false,
    showEmpty: false
  },
  Temperature: {
    labels: {
      format: '{value}°C',
    },
    title: {
      text: 'Temperature'
    },
    gridZIndex: -1,
    floor: null,
    showEmpty: false
  }
};

export function mapTypeToAxesData(type, sensorDataTypes = []) {
  const sensorTypeInfo = sensorDataTypes.find(dataType => dataType.key === type);
  if (sensorTypeInfo) {
    return {
      labels: {
        format: `{value}${sensorTypeInfo.unit}`,
      },
      title: {
        text: sensorTypeInfo.name,
      },
      gridZIndex: -1,
    };
  }
  return axes[type];
}

export function mapTypeToAxes(type, sensorDataTypes = []) {
  if (irradianceTypes.includes(type)) return 'Irradiance';
  if (type === 'Operational Energy' || type === 'Isolated Energy') return 'Energy';
  if (type === 'Pmp' || type === 'Pop' || type === 'Pmp STC') return 'P';
  if (type === 'Isc' || type === 'Imp' || type === 'Iop' || type === 'Imp STC') return 'I';
  if (type === 'Temperature') return 'Temperature';
  if (sensorDataTypes.map(dataType => dataType.key).includes(type)) return type;
  return 'V';
}

export function generateRandomColor() {
  let color = chroma.random().hex();
  while (chroma.contrast(color, '#ffffff') < 1) color = chroma.random().hex();
  return color;
}

export function extractEnergyData(data) {
  if (!Array.isArray(data)) return {};

  return data.reduce((acc, cur) => {
    cur.timestamp = (new Date(cur.timestamp)).getTime();
    if (acc[cur.moduleUuid]) acc[cur.moduleUuid].push([cur.timestamp, cur.energy]);
    else acc[cur.moduleUuid] = [[cur.timestamp, cur.energy]];
    return acc;
  }, {});
}

/* Returns an object of iv parameters, each iv parameter points to an array of [timestamp1, parameterValue1].
 * Parameters
 *  data: An array of points that contain IV data in the following format: { timestamp, data }
 *  gap: The maximum time difference between 2 points, if it exceeds that time, it will insert a gap point [timestampX, null]
 */
export function extractIVData(data, gap = 600001) {
  if (!Array.isArray(data)) return {};
  const previousTimestamp = {};

  return data.reduce((acc, cur) => {
    cur.timestamp = (new Date(cur.timestamp)).getTime();
    Object.keys(cur.data).forEach((type) => {
      if (cur.data[type] != null) {
        let discontinuous = false;
        if (previousTimestamp[type] && (cur.timestamp - previousTimestamp[type] > gap)) discontinuous = true;
        previousTimestamp[type] = cur.timestamp;

        if (discontinuous) acc[type].push([cur.timestamp, null]);
        if (acc[type]) acc[type].push([cur.timestamp, cur.data[type]]);
        else acc[type] = [[cur.timestamp, cur.data[type]]];
      }
    });

    return acc;
  }, {});
}

export function extractIVCurve(data) {
  if (!Array.isArray(data) || data.length === 0) return null;
  const [dataPoint] = data;
  const { timestamp, data: ivData } = dataPoint;
  const ivCurve = ivData.I.map((i, index) => ([ivData.V[index], i]));
  const prettyProperties = ['Imp', 'Isc', 'Pmp', 'Vmp', 'Voc', 'Iop', 'Pop', 'Vop', 'Temperature'].reduce((acc, cur) => {
    acc[cur.toLowerCase()] = ivData[cur] == null ? null : ivData[cur].toFixed(2);
    return acc;
  }, {});

  return {
    ivCurve,
    metaData: {
      ...prettyProperties,
      fillFactor: (ivData.Isc !== 0 && ivData.Voc !== 0) ? ((ivData.Pmp / (ivData.Isc * ivData.Voc)) * 100).toFixed(2) : 0,
      date: moment.parseZone(timestamp).format('dddd, MMM DD, HH:mm:ss'),
      x: (new Date(timestamp)).getTime(),
      y: ivData.Pmp
    }
  };
}

export function extractIVCurves(data) {
  if (!Array.isArray(data)) return [];
  return data.map(d => extractIVCurve([d]));
}

/* Returns an object of sensor parameters, each sensor parameter points to an array of [timestamp1, parameterValue1].
 * Parameters
 *  data: An array of points that contain sensor data in the following format: { timestamp, data }
 *  gap: The maximum time difference between 2 points, if it exceeds that time, it will insert a gap point [timestampX, null]
 */
export function extractSensorData(data, gap = 600001) {
  if (!Array.isArray(data)) return {};
  const previousTimestamp = {};

  return data.reduce((acc, cur) => {
    cur.timestamp = (new Date(cur.timestamp)).getTime();
    Object.keys(cur.data).forEach((type) => {
      if (cur.data[type] != null) {
        let discontinuous = false;
        if (previousTimestamp[type] && (cur.timestamp - previousTimestamp[type] > gap)) discontinuous = true;
        previousTimestamp[type] = cur.timestamp;

        if (discontinuous) acc[type].push([cur.timestamp, null]);
        if (acc[type]) acc[type].push([cur.timestamp, cur.data[type]]);
        else acc[type] = [[cur.timestamp, cur.data[type]]];
      }
    });

    return acc;
  }, {});
}

export function getIntervalMode(fromDate, toDate) {
  if (toDate.diff(fromDate, 'years') > 2) return 'year';
  if (toDate.diff(fromDate, 'months') > 2) return 'month';
  if (toDate.diff(fromDate, 'weeks') > 2) return 'week';
  if (toDate.diff(fromDate, 'days') > 2) return 'day';
  return 'hour';
}

function inRange(value, min, max) {
  if (min !== null && max !== null) {
    return min <= value && value <= max;
  }
  if (min === null) {
    return value <= max;
  }
  return value >= min;
}

export function getFilterZones(data, min, max) {
  // Divides data into zones, graying out points that are not within [min, max]
  const ranges = [];

  let rangeStart = null;
  let prev = null;
  data.forEach(({ timestamp, data: value }) => {
    const [key] = Object.keys(value);
    const isInRange = inRange(value[key], min, max);
    if (isInRange && !rangeStart) {
      rangeStart = timestamp;
    } else if (!isInRange && rangeStart) {
      const start = (new Date(rangeStart)).getTime();
      const end = (new Date(timestamp)).getTime();
      ranges.push({ value: start, color: '#D8D8D8' });
      ranges.push({ value: end });
      rangeStart = null;
    }
    prev = timestamp;
  });

  if (rangeStart && prev) {
    const start = (new Date(rangeStart)).getTime();
    const end = (new Date(prev)).getTime();
    ranges.push({ value: start, color: '#D8D8D8' });
    ranges.push({ value: end });
  }
  return ranges;
}

export function averageControlModules(modulesData) {
  // modulesData = [{ moduleUuid, data: [[timestamp, data], ...] }, ...]
  const moduleIds = modulesData.map(({ moduleUuid }) => moduleUuid);
  const dalyModuleData = {}; // { timestamp: { moduleUuid: value, ... }, ...}
  modulesData.forEach(({ moduleUuid, data }) => {
    if (!data) return;
    data.forEach(([timestamp, value]) => {
      if (!dalyModuleData[timestamp]) dalyModuleData[timestamp] = {};
      dalyModuleData[timestamp][moduleUuid] = value;
    });
  });

  const dalyAverages = [];
  const dalyMissingModules = []; // [{ timestamp, moduleIds }, ...]
  Object.entries(dalyModuleData).forEach(([timestamp, values]) => {
    const missing = moduleIds.filter(id => !Object.keys(values).includes(`${id}`) || values[id] === null);

    const nonNullValues = Object.values(values).filter(v => v !== null);
    if (nonNullValues.length) {
      if (missing.length) dalyMissingModules.push({ timestamp: +timestamp, moduleIds: missing });
    } else {
      return;
    }
    const sum = nonNullValues.reduce((acc, value) => acc + value, 0);
    const average = sum / nonNullValues.length;
    dalyAverages.push({ x: +timestamp, y: average, modules: moduleIds.filter(id => !missing.includes(id)) });
  });

  dalyAverages.sort(({ x: timestamp1 }, { x: timestamp2 }) => timestamp1 - timestamp2);
  dalyMissingModules.sort(({ timestamp: a }, { timestamp: b }) => a - b);
  return [dalyAverages, dalyMissingModules];
}

export function getIvTooltip(series, point, metaData, seriesName = '') {
  const { iop, vop, imp, vmp, pmp, isc, voc, fillFactor, temperature, irradiance } = metaData;

  let opPoint = '';
  if (iop !== null && vop !== null) {
    const popMatch = series.name === 'Operating Points';
    const popDivStyle = popMatch ? 'style="font-weight: bolder;"' : '';
    const popSpanStyle = popMatch ? 'style="color: #1cb150;"' : '';
    const pop = iop * vop;
    opPoint = `<div class="mt-2" ${popDivStyle}>
                <b>Iop: </b> <span ${popSpanStyle}>${iop} A</span><br/>
                <b>Vop: </b> <span ${popSpanStyle}>${vop} V</span><br/>
                <b>Pop: </b> <span ${popSpanStyle}>${pop.toFixed(2)} W</span>
              </div>`;
  }

  const vmpMatch = (+vmp).toFixed(2) === (+point.x).toFixed(2);
  const impMatch = (+imp).toFixed(2) === (+point.y).toFixed(2);
  const pmpDivStyle = vmpMatch && impMatch ? 'style="font-weight: bolder;"' : '';
  const pmpSpanStyle = vmpMatch && impMatch ? 'style="color: #1cb150;"' : '';
  const pmpPoint = `<div class="mt-2" ${pmpDivStyle}>
                      <b>Imp: </b> <span ${pmpSpanStyle}>${imp} A</span><br/>
                      <b>Vmp: </b> <span ${pmpSpanStyle}>${vmp} V</span><br/>
                      <b>Pmp: </b> <span ${pmpSpanStyle}>${pmp} W</span><br/>
                    </div>`;

  const otherValues = `<div class="mt-1">
                         <b>Isc:</b> ${isc} A<br/>
                         <b>Voc:</b> ${voc} V<br/>
                         <b>Fill Factor:</b> ${fillFactor}%<br/>
                       </div>`;

  let irrPoint = '';
  if (irradiance != null) {
    irrPoint = `<div class="mt-1">
                  <b>Irradiance:</b> ${irradiance} W/m2<br/>
                </div>`;
  }

  const pointName = point.displayName ? `<b>${point.displayName}</b><br/>` : '';
  const currPoint = `<div style="font-size: 17px;">
                       ${pointName}
                       <b>V: </b><span style="color: #1cb150; font-weight: bold;">${(+point.x).toFixed(3)} V</span>
                       <b>\xA0I: </b><span style="color: #1cb150; font-weight: bold;">${(+point.y).toFixed(3)} A</span><br/>
                     </div>`;

  const tempPoint = temperature != null ? `<div><b>Temperature:</b> ${temperature} °C<br/></div>` : '';

  const associations = metaData.associations || [];
  const associationPoints = associations.map((association, index) => {
    const value = association.y != null ? `${association.y} ${association.unit}` : null;
    if (index === 0) return `<div class="mt-1"><b>${association.name}:</b> ${value}</div>`;
    return `<div><b>${association.name}:</b> ${value}</div>`;
  }).join('');

  return `<span style="font-size: 10px">${metaData.date}</span><br/>
          <span style="color: ${point.color}">\u25CF</span>${seriesName}<br/>
          ${currPoint}
          ${otherValues}
          ${irrPoint}
          ${pmpPoint}
          ${opPoint}
          ${tempPoint}
          ${associationPoints}`;
}

export function exportTimeDependentDataToCSV(chart) {
  const series = chart.series.filter(s => s.visible);
  if (series.length === 0) return '';
  const { timezone } = chart.time.options;
  const headers = ['Timestamp', ...series.map(s => s.name)];
  const entries = {};

  series.forEach((s, index) => {
    s.options.data.forEach((d) => {
      let x;
      let y;
      if (Array.isArray(d)) [x, y] = d;
      else {
        x = d.x;
        y = d.y;
      }

      if (x < s.xAxis.min || x > s.xAxis.max) return;

      let timestamp;
      if (timezone) timestamp = moment.utc(x).tz(timezone);
      else timestamp = moment(x);
      timestamp.milliseconds(0);
      timestamp.seconds(0);

      const hash = timestamp.format('YYYY-MM-DD HH:mm');
      if (entries[hash]) {
        const entry = entries[hash];
        if (entry.data[index + 1] == null) entry.data[index + 1] = y;
        else if (entry.data[index + 1] != null && y != null) entry.data[index + 1] = (entry.data[index + 1] + y) / 2;
      } else {
        entries[hash] = { timestamp, data: new Array(series.length + 1).fill(null) };
        entries[hash].data[0] = hash;
        entries[hash].data[index + 1] = y;
      }
    });
  });

  const rows = Object.values(entries)
    .sort((entry1, entry2) => {
      if (entry1.timestamp.isBefore(entry2.timestamp)) return -1;
      if (entry1.timestamp.isAfter(entry2.timestamp)) return 1;
      return 0;
    })
    .map(entry => entry.data);

  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < headers.length; j++) {
      const value = rows[i][j];
      if (j > 0 && value != null) rows[i][j] = round(value, 3);
    }
  }

  let csv = `${headers.join(',')}\n`;
  rows.forEach((row) => {
    csv += `${row.join(',')}\n`;
  });

  return csv;
}

export function exportIVToCSV(chart) {
  const metaDataAttributes = [{ key: 'name', value: 'Module' }, { key: 'timestamp', value: 'Timestamp' }, { key: 'isc', value: 'Isc' },
    { key: 'voc', value: 'Voc' }, { key: 'fillFactor', value: 'Fill Factor' }, { key: 'imp', value: 'Imp' }, { key: 'vmp', value: 'Vmp' },
    { key: 'pmp', value: 'Pmp' }, { key: 'iop', value: 'Iop' }, { key: 'vop', value: 'Vop' }, { key: 'pop', value: 'Pop' },
    { key: 'temperature', value: 'Temperature' }];

  const ivCurveSeries = chart.series.filter(s => s.name !== 'Operating Points' && s.visible);
  if (ivCurveSeries.length === 0) return '';
  const length = Math.max(...ivCurveSeries.map(s => s.options.data.length));
  const rows = [];
  let rowNum = 0;

  for (let i = 0; i < length; i++) {
    for (let j = 0; j < ivCurveSeries.length; j++) {
      if (i === 0) {
        metaDataAttributes.forEach((attribute, index) => {
          if (rows[index] == null) rows[index] = [];
          if (attribute.key === 'name') rows[index].push('Module', ivCurveSeries[j].name, '');
          else if (attribute.key === 'timestamp') rows[index].push('Timestamp', (ivCurveSeries[j].options.metaData.date).replace(/,/g, ''), '');
          else if (['iop', 'vop', 'pop', 'temperature'].includes(attribute.key)) {
            const value = ivCurveSeries[j].options.metaData[attribute.key];
            rows[index].push(attribute.value, value != null ? value : 'N/A', '');
          } else rows[index].push(attribute.value, ivCurveSeries[j].options.metaData[attribute.key], '');
        });

        rowNum = metaDataAttributes.length;
        if (ivCurveSeries[j].options.metaData.associations) {
          ivCurveSeries[j].options.metaData.associations.forEach((association, index) => {
            if (rows[rowNum + index] == null) rows[rowNum + index] = [];
            rows[rowNum + index].push(association.name, association.y != null ? association.y : '', '');
          });

          rowNum += ivCurveSeries[j].options.metaData.associations.length;
        }

        if (rows[rowNum] == null) rows[rowNum] = [];
        rows[rowNum].push('', '', '');

        if (rows[rowNum + 1] == null) rows[rowNum + 1] = [];
        rows[rowNum + 1].push('Voltage', 'Current', '');
      }

      let x = '';
      let y = '';
      const d = ivCurveSeries[j].options.data[i];
      if (d != null) {
        if (Array.isArray(d)) [x, y] = d;
        else {
          x = d.x;
          y = d.y;
        }
      }

      if (rows[rowNum + 2 + i] == null) rows[rowNum + 2 + i] = [];
      rows[rowNum + 2 + i].push(x, y, '');
    }
  }

  let csv = '';
  rows.forEach((row) => {
    csv += `${row.join(',')}\n`;
  });

  return csv;
}

function interpolate(points) {
  if (points.length === 0) return () => 0;
  if (points.length === 1) return () => points[0][1];

  const n = points.length - 1;
  points = points.sort((a, b) => a[0] - b[0]);
  const [first] = points;

  // const leftExtrapolated = (x) => {
  //   const a = points[0];
  //   const b = points[1];
  //   return a[1] + (x - a[0]) * (b[1] - a[1]) / (b[0] - a[0]);
  // };

  const interpolated = (x, a, b) => (a[1] + (x - a[0]) * (b[1] - a[1]) / (b[0] - a[0]));

  // const rightExtrapolated = (x) => {
  //   const a = points[n - 1];
  //   const b = points[n];
  //   return b[1] + (x - b[0]) * (b[1] - a[1]) / (b[0] - a[0]);
  // };

  return (x) => {
    if (x < first[0]) return null;
    if (x === first[0]) return first[1];

    for (let i = 0; i < n; i += 1) {
      if (x > points[i][0] && x <= points[i + 1][0]) {
        return interpolated(x, points[i], points[i + 1]);
      }
    }

    return null;
  };
}

export function exportInterpolatedIVToCSV(chart) {
  const metaDataAttributes = [{ key: 'name', value: 'Module' }, { key: 'timestamp', value: 'Timestamp' }, { key: 'isc', value: 'Isc' },
    { key: 'voc', value: 'Voc' }, { key: 'fillFactor', value: 'Fill Factor' }, { key: 'imp', value: 'Imp' }, { key: 'vmp', value: 'Vmp' },
    { key: 'pmp', value: 'Pmp' }, { key: 'iop', value: 'Iop' }, { key: 'vop', value: 'Vop' }, { key: 'pop', value: 'Pop' },
    { key: 'temperature', value: 'Temperature' }];

  const rows = [];
  const ivCurveSeries = chart.series.filter(s => s.name !== 'Operating Points' && s.visible);
  if (ivCurveSeries.length === 0) return '';

  ivCurveSeries.forEach((s, i) => {
    metaDataAttributes.forEach((attribute, index) => {
      if (rows[index] == null) rows[index] = [];
      if (i === 0) rows[index].push(attribute.value);
      if (attribute.key === 'name') rows[index].push(s.name);
      else if (attribute.key === 'timestamp') rows[index].push((s.options.metaData.date).replace(/,/g, ''));
      else if (['iop', 'vop', 'pop', 'temperature'].includes(attribute.key)) {
        rows[index].push(s.options.metaData[attribute.key] != null ? s.options.metaData[attribute.key] : 'N/A');
      } else rows[index].push(s.options.metaData[attribute.key]);
    });

    let rowNum = metaDataAttributes.length;
    if (s.options.metaData.associations) {
      s.options.metaData.associations.forEach((association, index) => {
        if (rows[rowNum + index] == null) rows[rowNum + index] = [];
        if (i === 0) rows[rowNum + index].push(association.name);
        rows[rowNum + index].push(association.y != null ? association.y : '');
      });

      rowNum += s.options.metaData.associations.length;
    }

    if (rows[rowNum] == null) rows[rowNum] = [];
    if (i === 0) rows[rowNum].push('');
    rows[rowNum].push('');

    if (rows[rowNum + 1] == null) rows[rowNum + 1] = [];
    if (i === 0) rows[rowNum + 1].push('Voltage');
    rows[rowNum + 1].push((s.options.metaData.date).replace(/,/g, ''));
  });

  const rowIndex = rows.length;
  const interpolatedXaxis = range(0, 50.5, 0.5);
  const interpolationSeries = ivCurveSeries.map((s) => {
    const filtered = {};
    s.options.data.forEach((d) => {
      let xy = [null, null];
      if (d && Array.isArray(d)) xy = [d[0], d[1]];
      else if (d) xy = [d.x, d.y];

      if (xy[0] == null || xy[1] == null) return;
      const [x, y] = xy;
      if (filtered[x]) filtered[x] = (filtered[x] + y) / 2;
      else filtered[x] = y;
    });

    return interpolate(Object.keys(filtered).map(x => [Number.parseFloat(x), filtered[x]]));
  });

  for (let i = 0; i < interpolatedXaxis.length; i++) {
    const x = interpolatedXaxis[i];
    ivCurveSeries.forEach((s, index) => {
      const interpolator = interpolationSeries[index];
      const y = interpolator(x);
      if (rows[rowIndex + i] == null) rows[rowIndex + i] = [x];
      if (y != null) rows[rowIndex + i].push(y);
      else rows[rowIndex + i].push('');
    });
  }

  let csv = '';
  rows.forEach((row) => {
    csv += `${row.join(',')}\n`;
  });

  return csv;
}
