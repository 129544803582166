<script>
import { Chart } from 'highcharts-vue/src';
import Highcharts from 'highcharts/highcharts';
import loadExporting from 'highcharts/modules/exporting';
import loadExportingOffline from 'highcharts/modules/offline-exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadNoData from 'highcharts/modules/no-data-to-display';
import boost from 'highcharts/modules/boost';
import ResizeObserver from 'resize-observer-polyfill';
import FileSaver from 'file-saver';
import moment from 'moment-timezone';
import { throttled } from '../helpers/helpers';
import { exportTimeDependentDataToCSV, exportIVToCSV, exportInterpolatedIVToCSV } from './graphs/helpers';

window.moment = moment;

loadExporting(Highcharts);
loadExportingOffline(Highcharts);
loadExportData(Highcharts);
loadNoData(Highcharts);
boost(Highcharts);

Highcharts.setOptions({
  credits: {
    enabled: false
  },
  exporting: {
    fallbackToExportServer: false,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadSVG',
          'downloadCSV'
        ]
      }
    }
  },
  time: { useUTC: false },
  global: { useUTC: false }
});

const buttons = Highcharts.getOptions().exporting.buttons.contextButton.menuItems;
const timeDependentButtons = buttons.slice(0, buttons.length - 1);
timeDependentButtons.push({
  text: 'Download CSV',
  onclick: function onclick() {
    const csv = exportTimeDependentDataToCSV(this);
    const blob = new Blob([csv], { type: 'text/csv' });
    FileSaver.saveAs(blob, `${this.options.exporting.filename}.csv` || 'chart.csv');
  }
});

const ivButtons = buttons.slice(0, buttons.length - 1);
ivButtons.push({
  text: 'Download CSV',
  onclick: function onclick() {
    const csv = exportIVToCSV(this);
    const blob = new Blob([csv], { type: 'text/csv' });
    FileSaver.saveAs(blob, `${this.options.exporting.filename}.csv` || 'chart.csv');
  }
});

ivButtons.push({
  text: 'Download CSV (Interpolated)',
  onclick: function onclick() {
    const csv = exportInterpolatedIVToCSV(this);
    const blob = new Blob([csv], { type: 'text/csv' });
    FileSaver.saveAs(blob, `${this.options.exporting.filename}.csv` || 'chart.csv');
  }
});

export { buttons, timeDependentButtons, ivButtons };

export default {
  extends: Chart,
  methods: {
    onResize() {
      this.$emit('resize');
      if (this.chart && this.chart.options) {
        this.chart.reflow();
      }
    }
  },
  mounted() {
    const events = { redraw: () => this.$emit('render') };
    this.options.chart = this.options.chart ? Object.assign(this.options.chart, events) : events;
    Chart.mounted.call(this);

    this.$el.className += ' highcharts-container';
    const resizeObserver = new ResizeObserver(throttled(() => this.onResize()));
    resizeObserver.observe(this.$el);
  }
};
</script>

<style scoped>
.highcharts-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

</style>
